import React from 'react';
import './Tokenomics.css';

const Tokenomics = () => {
    // Bonus Tiers Data from the PDF
    const bonusTiers = [
        { range: '$4,950 - $5,999', bonus: '20.0%', bottomRange: '$4,950', bottomBonus: '$990', topRange: '$5,999', topBonus: '$1,200' },
        { range: '$6,000 - $9,999', bonus: '22.5%', bottomRange: '$6,000', bottomBonus: '$1,350', topRange: '$9,999', topBonus: '$2,250' },
        { range: '$10,000 - $14,999', bonus: '25.0%', bottomRange: '$10,000', bottomBonus: '$2,500', topRange: '$14,999', topBonus: '$3,750' },
        { range: '$15,000 - $24,999', bonus: '27.5%', bottomRange: '$15,000', bottomBonus: '$4,125', topRange: '$24,999', topBonus: '$6,875' },
        { range: '$25,000 - $49,999', bonus: '30.0%', bottomRange: '$25,000', bottomBonus: '$7,500', topRange: '$49,999', topBonus: '$15,000' },
        { range: '$50,000 - $74,999', bonus: '32.5%', bottomRange: '$50,000', bottomBonus: '$16,250', topRange: '$74,999', topBonus: '$24,375' },
        { range: '$75,000 - $106,000', bonus: '35.0%', bottomRange: '$75,000', bottomBonus: '$26,250', topRange: '$106,000', topBonus: '$37,100' }
    ];

    return (
        <div className="tokenomics-container">
            <h2 className="main-title">PSMTX TOKENOMICS</h2>
            {/* <p className="description">
                PSMTX is a utility token offering bonuses on <strong>MTXE01</strong> security tokens, appraised at $100.65 per carat.
                By purchasing <strong>PSMTX</strong>, users can enjoy bonuses ranging from 1% to 30% on MTXE01 security tokens.
            </p> */}
            {/* <hr /> */}
            {/* <br /> */}
            <p className="description">
                PSMTX is a utility token offering bonuses on <strong>MTXE01</strong> security tokens, appraised at <strong>$100.65 per carat</strong>.
                By purchasing <strong>PSMTX</strong>, users can enjoy bonuses ranging from <strong>20% to 35%</strong> on MTXE01 security tokens.
            </p>

            <p className='tokenomics-note'>Note: There will only be a limited supply of PSMTX tokens available.</p>

            {/* Bonus Tiers Chart */}
            <div className="bonus-tiers">
                <h3 className="sub-title">MoneyTronix Bonus Tiers Chart</h3>
                <table className="bonus-table">
                    <thead>
                        <tr>
                            <th>PSMTX Purchase Tier</th>
                            <th>Bonus (%)</th>
                            <th>Bottom Range (USD)</th>
                            <th>MTXE01 Bonus Received: Bottom of Purchase Range (USD)</th>
                            <th>Top Range (USD)</th>
                            <th>MTXE01 Bonus Received: Top of Purchase Range (USD)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bonusTiers.map((tier, index) => (
                            <tr key={index}>
                                <td>{tier.range}</td>
                                <td>{tier.bonus}</td>
                                <td>{tier.bottomRange}</td>
                                <td>{tier.bottomBonus}</td>
                                <td>{tier.topRange}</td>
                                <td>{tier.topBonus}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="fundraising-goal">
                <p>The future of real-world asset-backed security tokens is here.</p>
            </div>
        </div>
    );
}

export default Tokenomics;
