import React, { useState } from 'react';


import './About.css';

const About = () => {
    const [showVideo, setShowVideo] = useState(false);

    const handleOpenVideo = () => {
        setShowVideo(true);
    };

    const handleCloseVideo = () => {
        setShowVideo(false);
    };

    return (
        <div className="about-us">

            <h2>About PSMTX Token</h2>
            <p>
                <strong>Moneytronix International Capital Partners, LLC</strong> is at the forefront of revolutionizing real-world asset investment through blockchain technology. By combining tangible high-value assets with the efficiency of blockchain, we are offering investors a unique opportunity to participate in a decentralized future.
            </p>
            <p>
                Our Utility Token <strong> (PSMTX) </strong> provides early investors with exclusive bonuses and first-in-line access to the future launch of our Security Token Offering <strong>(MTXE01)</strong>. Accredited US investors in the U.S. and global investors can participate in this groundbreaking opportunity.
            </p>
            <hr />
            <h3>What Makes MTXE01 Unique?</h3>
            <p>
                MTXE01 tokens are backed by **real-world gemstones**, stored securely in bank <strong> vaults </strong> and verified with <strong> RFID technology </strong>. Through **Chainlink Protocol Proof of Reserve <a href="https://chain.link/proof-of-reserve" class="por-link">(POR)</a>**, Investors benefit from complete transparency and can verify the on-chain assets backing their tokens at any time.
            </p>
            <p>
                This ensures that every token is securely tied to real, tangible assets, creating a transparent, trustworthy, and secure ecosystem for investors. Unlike most cryptocurrencies, MTXE01 offers stability through its asset-backed structure while also tapping into the high-growth potential of tokenization.
            </p>
            <hr />
            <h3>Why Invest Now?</h3>
            <p>
                Early investors in PSMTX receive exclusive benefits:
            </p>
            <ul>
                <li><strong>Exclusive bonuses</strong> on tokens before the Security Token Offering.</li>
                <li><strong>First-Mover Advantage</strong> in the compliant, real-world asset-backed token market.</li>
                <li><strong>Unmatched Transparency</strong> with on-chain verification through Chainlink Proof of Reserve<a href="https://chain.link/proof-of-reserve" class="por-link">(POR)</a></li>
                <li><strong>Cutting-Edge Asset Security</strong> with RFID-tagged gemstones stored in secured bank vaults, ensuring confidence in the value of your investments.</li>
            </ul>
            <hr />
            <h3>How Our Security Tokens Work</h3>
            <p>
                Investing in our PSMTX Utility Token secures exclusive benefits for our upcoming Security Token Offering. Our compliant security tokens represent fractional ownership in high-value assets, such as rare gemstones stored under strict security.
                These tokens are linked to the underlying assets’ performance and can be traded on blockchain platforms, offering liquidity that traditional markets lack. The Chainlink Proof of Reserve mechanism ensures a transparent and verifiable asset-to-token ratio.
            </p>
            <hr />
            <h3>Why Chainlink Proof of Reserve?</h3>
            <p>
                Chainlink Proof of Reserve<a href="https://chain.link/proof-of-reserve" className="por-link">(POR)</a> enhances blockchain transparency, allowing investors to verify that the assets backing our tokens are securely stored and accurately valued. Each token is backed 1:1 with real assets that can be tracked in real-time, fostering trust between issuer and investor.
            </p>
            <h3></h3>
            <p>
                By investing in PSMTX, you’re participating in a cutting-edge financial ecosystem that combines the security of traditional finance with the innovation of blockchain technology.
            </p>
            <p>
                <strong>Steps to Participate:</strong>
                <ol>
                    <li>Verify your status as an accredited US investor (U.S. Investors).</li>
                    <li>Comply with our streamlined KYC/AML process (International Investors).</li>
                    <li>Purchase PSMTX Utility Tokens and secure your bonuses and priority access.</li>
                </ol>
            </p>
            <hr />
            <h2>Are You Ready to Join the Future?</h2>
            <p>
                The future of real-world asset-backed tokens is here, and you don’t want to miss out. Secure your spot and be part of a compliant, transparent, and innovative financial ecosystem.
            </p>
            <div className="video-preview" onClick={handleOpenVideo}>
                <img
                    src="https://img.youtube.com/vi/zcT37AwRV-8/maxresdefault.jpg"
                    alt="Video Preview"
                    className="video-thumbnail"
                />
                <div className="play-button-overlay">
                    <span>▶</span>
                </div>
            </div>

            {showVideo && (
                <div className="video-modal">
                    <div className="video-modal-content">
                        <button className="close-video" onClick={handleCloseVideo}>
                            ×
                        </button>
                        <iframe
                            src="https://www.youtube.com/embed/zcT37AwRV-8?autoplay=1&playsinline=1&controls=1"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="PSMTX Video"
                        ></iframe>
                    </div>
                </div>
            )}
            <p>
                {/* Contact us today for more information on how to participate in the PSMTX token sale. */}
                If you have any additional questions about participating in the PSMTX token sale, feel free to contact us.
            </p>
        </div>
    );
}

export default About;