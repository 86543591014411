import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import About from './components/About/About';
import Tokenomics from './components/Tokenomics/Tokenomics';
import DigitalPaper from './components/DigitalPaper/DigitalPaper';
import PreSale from './components/PreSale/PreSale';
import EmeraldHistory from "./components/EmeraldHistory/EmeraldHistory";

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        
        <Routes>
          <Route path="/" element={<PreSale />} />
          <Route path="/about" element={<About/>} />
          <Route path="/tokenomics" element={<Tokenomics/>} />
          <Route path="/emerald-history" element={<EmeraldHistory/>} />
          <Route path="/digital-paper" element={<DigitalPaper/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;