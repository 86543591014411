import React, { useState, useEffect, useRef, useMemo } from 'react';
import './PreSale.css';
import { Link } from 'react-router-dom';

function PreSale() {
  const presaleStartDate = useMemo(() => new Date(Date.UTC(2024, 9, 10, 1, 0, 0)), []);
  const presaleEndDate = useMemo(() => new Date(Date.UTC(2024, 9, 15, 1, 0, 0)), []);
  const [timeLeft, setTimeLeft] = useState({});
  const [progress, setProgress] = useState(0);
  const [presaleState, setPresaleState] = useState('upcoming'); // upcoming, live, ended
  const timerRef = useRef(null);

  const handlePresaleLink = () => {
    const url = `https://www.pinksale.finance/launchpad/base/0x2ae4FDE573cb34d77e1A8FafC3f7fcc4203B1821`;
    window.location.href = url;
  };

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date();
      if (now < presaleStartDate) {
        // Pre-sale is upcoming
        const remainingTimeToStart = presaleStartDate - now;
        const days = Math.floor(remainingTimeToStart / (1000 * 60 * 60 * 24));
        const hours = Math.floor((remainingTimeToStart % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((remainingTimeToStart % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTimeToStart % (1000 * 60)) / 1000);
        setTimeLeft({ days, hours, minutes, seconds });
        setPresaleState('upcoming');
        setProgress(0);
      } else if (now >= presaleStartDate && now <= presaleEndDate) {
        // Pre-sale is live
        const remainingTime = presaleEndDate - now;
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });

        const totalPresaleDuration = presaleEndDate - presaleStartDate;
        const timePassed = now - presaleStartDate;
        const progressPercentage = Math.min((timePassed / totalPresaleDuration) * 100, 100);

        setProgress(progressPercentage);
        setPresaleState('live');
      } else {
        // Pre-sale ended
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0, ended: true });
        setProgress(100);
        setPresaleState('ended');
        clearInterval(timerRef.current);
      }
    };

    updateTimer();
    timerRef.current = setInterval(updateTimer, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [presaleEndDate, presaleStartDate]);

  return (
    <div className="presale-page">
      <section className="top-section">
        <div className="progress-container">
          <div className="progress-logo-container">
            <img className="progress-logo-img" src="/ticker_logo.png" alt="logo" />
          </div>
          <div className="title">
            {presaleState === 'upcoming' && (
              <h2 style={{ color: 'orange', marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>PSMTX Pre-Sale Starts In</h2>
            )}
            {presaleState === 'live' && (
              <h2>PSMTX Pre-Sale Live</h2>
            )}
            {presaleState === 'ended' && (
              <h2 style={{ color: 'red', marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>PSMTX Pre-Sale Ended</h2>
            )}
          </div>

          {/* Time Left / Pre-sale Ended Display */}
          <div className="presale-timer">
            <div className="time-value">
              <span>{String(timeLeft.days).padStart(2, '0')}</span>
              <span>Days</span>
            </div>
            <div className="time-value">
              <span>{String(timeLeft.hours).padStart(2, '0')}</span>
              <span>Hours</span>
            </div>
            <div className="time-value">
              <span>{String(timeLeft.minutes).padStart(2, '0')}</span>
              <span>Mins</span>
            </div>
            <div className="time-value">
              <span>{String(timeLeft.seconds).padStart(2, '0')}</span>
              <span>Secs</span>
            </div>
          </div>

          {/* End Date */}
          <div className="ends-on">
            {presaleState === 'upcoming' && (
              <>
                <span>Token Sales Starts In </span>
                <span>Wed Oct 9 2024 at 13:30 UTC</span>
              </>
            )}
            {presaleState === 'live' && (
              <>
                <span>Token Sales Ends In </span>
                <span>{presaleEndDate.toDateString()} at 21:30 UTC</span>
              </>
            )}
            {presaleState === 'ended' && (
              <>
                <span>Token Sales Ended On </span>
                <span>{presaleEndDate.toDateString()} at 21:30 UTC</span>
              </>
            )}
          </div>

          {/* Progress Bar */}
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
          <div className="capped-msg">
            <p>
              <strong>{Math.round(progress)}%</strong> of the pre-sale time passed
            </p>
          </div>

          {/* {presaleState === 'live' && ( */}
          <button className="cta-button" onClick={handlePresaleLink}>
            Participate Now
          </button>
          {/* )} */}
        </div>

        <div className="content-right">
          <h1>
            Unlock Exclusive bonuses on Our Utility Token NOW — Ahead of Our Security Token
            Launch!
          </h1>
          <p>
            Unlock the potential of PSMTX—your key to exclusive bonuses on MTXE01! Join us today
            and be part of the future of real-world asset tokenization!
          </p>
          <Link to="/about">
            <button className="cta-button-right">Learn More</button>
          </Link>
        </div>
      </section>

      {/* Token Information Section */}
      <section className="token-info-section">
        <h2>Token Information</h2>
        <div className="token-info">
          <div className="info-box">
            <h2>PSMTX Special Offer</h2>
            <p>Only On PinkSale</p>
          </div>
          <div className="info-box">
            <h2>MTXE01 bonuses</h2>
            <p>Up to 35% off</p>
          </div>
          <div className="info-box">
            <h2>Soft Cap</h2>
            <p>$26,500</p>
          </div>
          <div className="info-box">
            <h2>Hard Cap</h2>
            <p>$106,000</p>
          </div>
        </div>
      </section>

      {/* Why Invest Section */}
      <section className="why-invest">
        <h2>Why Invest in PSMTX?</h2>
        <p>
          You’ll get special access to exclusive bonuses and priority benefits when we launch our
          Security Token (MTXE01).
        </p>
        <div className="features">
          <div className="feature">
            <h3>Security</h3>
            <p>MTXE01 is backed by real-world assets, stored in secured bank vaults with RFID tagging.</p>
          </div>
          <div className="feature">
            <h3>Transparency</h3>
            <p>
              Powered by
              <a href="https://chain.link/proof-of-reserve" className="por-link">
                Chainlink Proof of Reserve (POR)
              </a>
              , ensuring verifiable assets at all times.
            </p>
          </div>
          <div className="feature">
            <h3>Exclusive bonuses</h3>
            <p>Get early-bird prices before the official launch.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PreSale;
