import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './Header.css';

function Header() {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className='header'>
            <div className='logo'>
                <Link to="/"><img className='logo-img' src="/logo.png" alt="Logo" /></Link>
            </div>
            <div className='header-tabs'>
                <nav>
                    <ul>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/emerald-history" >Emerald History</Link></li>
                        <li><Link to="/tokenomics">Tokenomics</Link></li>
                        <li><Link to="/digital-paper">Digital Paper</Link></li>
                        <li onClick={toggleSidebar}>
                            <i className="fa-solid fa-bars"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className={`sidebar-tabs ${sidebarOpen ? 'active' : ''}`}>
                <nav>
                    <ul>
                        <li onClick={toggleSidebar}>
                            <i className="fa-solid fa-xmark" style={{
                                textDecoration: 'none',
                                fontSize: '20px',
                                fontWeight: 600,
                                letterSpacing: '1px',
                                lineHeight: '24px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                textTransform: 'uppercase',
                                transition: 'all .5s',
                                color: '#007ACC',
                                display: 'flex',
                                alignItems: 'center'
                            }}></i>
                        </li>
                        <li><Link to="/about" onClick={toggleSidebar}>About</Link></li>
                        <li><Link to="/emerald-history" onClick={toggleSidebar} >Emerald History</Link></li>
                        <li><Link to="/tokenomics" onClick={toggleSidebar}>Tokenomics</Link></li>
                        <li><Link to="/digital-paper" onClick={toggleSidebar}>Digital Paper</Link></li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Header;