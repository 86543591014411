import React from 'react';
import './EmeraldHistory.css'; // Ensure this path matches your file structure

function EmeraldHistory() {
  return (
    <div className="about-us">
      <h2>Rare Emerald History: MTXE01</h2>
      <p>
        Emeralds, known for their rich, verdant color, are among the most coveted gemstones in the world, representing both beauty and rarity. They have been cherished for millennia, revered by ancient civilizations and modern collectors alike for their vivid green hues and their association with power, immortality, and renewal. Our first tokenized gemstones, <strong>MTXE01</strong>, are rare and highly valued emeralds with an appraised value of <strong>$100.65</strong> per carat, offering investors the opportunity to own a piece of something truly extraordinary.
      </p>
      <hr />
      <h3>Why Emeralds Are So Sacred and Highly Valued</h3>
      <p>
        Emeralds are more than just precious stones; they are symbols of <strong>wealth, status, and rarity</strong>. In ancient Egypt, they were believed to offer eternal youth, with Cleopatra famously hoarding vast quantities of the gems as a demonstration of her regal splendor. To the Incas and Aztecs, emeralds were considered sacred stones, used in religious ceremonies and as offerings to the gods.
        Today, emeralds continue to hold their place as one of the most sought-after gems, with only a <strong>limited number of high-quality stones</strong> available in the world. The vibrant green color, resulting from traces of chromium and vanadium, is what sets emeralds apart. Unlike other gems, the more intense and pure the green, the more valuable the emeralds become.
      </p>
      <hr />
      <h3>The Rarity of the MTXE01 Emeralds</h3>
      <p>
        <strong>MTXE01</strong> are not just any emeralds — it’s one of the few <strong>exceptionally rare gemstones</strong> available on the global market. Their stunning clarity and color are a result of millions of years of natural formation deep within the earth's crust. Unlike diamonds, which are relatively abundant, high-quality emeralds are rare, and those with excellent color, clarity, and cut, like MTXE01, are even more rare.
      </p>
      <p><strong>Why are they so rare ?</strong></p>
      <ul>
        <li>Only specific geological conditions can create emeralds of this caliber.</li>
        <li>The finest emeralds are found in <strong>Colombia</strong>, <strong>Brazil</strong>, and <strong>Zambia</strong>, but the supply of top-quality stones is incredibly limited.</li>
        <li>As mining depletes the best sources, truly remarkable emeralds are becoming harder to find, driving their <strong>value even higher</strong>.</li>
      </ul>
      <hr />
      <h3>A Limited Opportunity to Own a Piece of History</h3>
      <p>
      Owning <strong>MTXE01</strong> means holding tangible and valuable pieces of the earth's ancient history: gems formed over millions of years under perfect conditions. Unlike mass-produced assets, emeralds are <strong>finite</strong> — there will never be an infinite supply of rare stones like these.
      </p>
      <p>
        As an investor in MTXE01, you are acquiring more than just tokenized gemstones; you are securing a part of a <strong>scarce global resource</strong>, the kind of asset that has consistently grown in value over time due to its rarity and desirability. Emeralds are among the few timeless gems that continue to capture the attention of collectors and connoisseurs worldwide.
      </p>
      <hr />
      <h3>A Jewel Fit for Royalty and Investment</h3>
      <p>
        These emeralds are beautiful — it’s also an incredible investment. Over centuries, emeralds have maintained their appeal, consistently outperforming other assets in times of economic uncertainty. With <strong>MTXE01</strong>, you have the chance to join an elite group of investors who understand the enduring value of <strong>rare, high-quality gemstones</strong>.
      </p>
      <p>
        By investing in MTXE01, you are not just acquiring gemstones; you are gaining access to a highly desired, scarce commodity that has long been a symbol of power, grace, and timeless elegance. Only so many such emeralds exist in the world, and even fewer are available for purchase, making MTXE01 a truly unique investment opportunity.
      </p>
    </div>
  );
}

export default EmeraldHistory;