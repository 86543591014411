import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="branding">
          <h2>Moneytronix International Capital Partners, LLC</h2>
          <p>Connecting Real-World Assets with Blockchain Innovation</p>
        </div>
        <div className="footer-icons">
          <ul>
            <li>
              <a href="https://www.linkedin.com/company/mtx-gems/about/" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/imtx2024" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/people/MoneyTronix-International-Capital-Partners-LLC/61566971641922/?rdid=6vX96S5e17L9y9zd&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FYp6nQdZxvhZy9kTL%2F" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-facebook"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className='telecom'>
          <span><i className="fa-solid fa-envelope"></i><strong> info@moneytronix.us</strong></span>
        </div>
        <p>&copy; 2024 Moneytronix. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;




























